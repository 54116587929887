import request from '@/utils/request'
import qs from 'qs' //引入qs模块


//考勤打卡详情
export function attendanceClockIn(query) {
  return request({
    url: 'rSAttendanceDetails/attendanceClockIn',
    method: 'get',
    params: query
  })
}



//判断坐标点是否位于基点打卡范围内(经纬度采用WGS 84坐标系)
export function isPointWithinRadius(query) {
  return request({
    url: 'rSAttendanceDetails/isPointWithinRadius',
    method: 'get',
    params: query
  })
}



//判断打卡是否正常并返回打卡状态:0 正常,1 迟到,2 旷工半天,3 旷工一天,4 早退
export function isAbnormalClock(query) {
  return request({
    url: 'rSAttendanceDetails/isAbnormalClock',
    method: 'get',
    params: query
  })
}




//新增考勤
export function getAttendanceDetails(query) {
	return request({
		url: 'rSAttendanceDetails/rSAttendanceDetails',
		method: 'post',
		data: query
	})
}




//位置上报
export function locationReporting(query) {
	return request({
		url: 'rSLocationReporting/rSLocationReporting',
		method: 'post',
		data: query
	})
}


//位置上报列表
export function listLocation(query) {
  return request({
    url: 'rSLocationReporting/rSLocationReportingList',
    method: 'get',
    params: query
  })
}



//考勤基点设置 列表
export function pointSetting(query) {
  return request({
    url: 'rSAttendancePointSetting/rSAttendancePointSettingPageList',
    method: 'get',
    params: query
  })
}



//新增考勤基点设置
export function newSetpointSetting(query) {
  return request({
    url: 'rSAttendancePointSetting/rSAttendancePointSetting',
    method: 'post',
    data: query
  })
}


//修改考勤基点设置
export function newSetpointSettingEdit(query) {
  return request({
    url: 'rSAttendancePointSetting/rSAttendancePointSetting',
    method: 'put',
    data: query
  })
}



//新增考勤基点设置
  export function deleteSetting(data) {
  return request.delete(`rSAttendancePointSetting/rSAttendancePointSetting`, {data:[data]})
}




//企业微信服务
export function getJSSDK(query) {
  return request({
    url: 'sysWeChat/jsSdkParameter',
    method: 'get',
    params: query
  })
}


//新增考勤基点设置---详情
export function dancePointDetail(id, query = {}) {
  return request({
    url: `rSAttendancePointSetting/rSAttendancePointSetting/${id}`,
    method: 'get',
    params: query
  })
}



//查询部门及所属人员列表【搜索下拉框专用】
export function orgUserInfoList(id, query = {}) {
  return request({
    url: `sysOrg/orgUserInfoList`,
    method: 'get',
    params: query
  })
}



//企业微信菜单
export function qyWechatMenuTreeData(id, query = {}) {
  return request({
    url: `sysMenu/wechatTreeDataByRole`,
    method: 'get',
    timeout: 10000,// 这里设置超时时间为10000毫秒，即10秒
    headers: {
      'Content-Type': 'application/json'
    },
    params: query
  })
}




//从位置上报列表
export function LocationReportingList(query) {
  return request({
    url: `rSLocationReporting/rSLocationReportingList`,
    method: 'get',
    params: query
  })
}

//下拉字典
export function InfacelistByCode(data) {
  return request({
    url: `sysDict/listByCode`,
    method: 'get',
    params: data
  })
}

export function getWeChatInfo(query) {
  return request({
    url: 'sysLogin/loginUserInfoByWeChatUserId',
    method: 'get',
    params: query
  })
}